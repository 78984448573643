import { fontFilenames } from "@Tokens";
import type { FontFace, FontPalette } from "../types";

const fallbackHeadingFont = "Fallback Arial Heading";
const fallbackBodyFont = "Fallback Arial Body";

export const fonts: FontPalette = {
	body: `Inter, "${fallbackBodyFont}"`,
	heading: `"National 2", "${fallbackHeadingFont}"`,
};

// The range of special characters we'd like to add to the fonts.
// Represents the special characters found in a Sanity backup:
// ['È', 'ο', 'ş', 'ī', 'ž̈', 'ï', 'ø', 'á', 'Ì', 'ţ', 'Å', 'ś', 'ý', 'æ', 'ŏ', 'Ö', 'ō', 'ß', 'Þ', 'Ü', 'ā', 'º', 'ô', 'é', 'ă', 'Ż', 'ν', 'Æ', 'Ó', 'õ', 'Ġ', 'ę', 'ğ', 'ł', 'ö', 'À', 'ú', 'å', 'ü', 'ț', 'Ž', 'í', 'î', 'ê', 'ð', 'ç', 'š', 'à', 'Î', 'ġ', 'ḗ', 'ħ', 'ä', 'ū', 'ñ', 'ő', 'Ħ', 'ù', 'œ', 'ί', 'ψ', 'Ċ', '¿', 'û', 'ì', 'Š', 'ć', 'ș', 'Á', 'č', 'þ', 'ã', 'â', 'ò', 'Ç', 'ż', 'ó', 'ë', 'Ø', 'É', 'Í', 'è', 'ả']
export const unicodeRange = [
	"U+00C8",
	"U+03BF",
	"U+015F",
	"U+012B",
	"U+017E",
	"U+0308",
	"U+00EF",
	"U+00F8",
	"U+00E1",
	"U+00CC",
	"U+0163",
	"U+00C5",
	"U+015B",
	"U+00FD",
	"U+00E6",
	"U+014F",
	"U+00D6",
	"U+014D",
	"U+00DF",
	"U+00DE",
	"U+00DC",
	"U+0101",
	"U+00BA",
	"U+00F4",
	"U+00E9",
	"U+0103",
	"U+017B",
	"U+03BD",
	"U+00C6",
	"U+00D3",
	"U+00F5",
	"U+0120",
	"U+0119",
	"U+011F",
	"U+0142",
	"U+00F6",
	"U+00C0",
	"U+00FA",
	"U+00E5",
	"U+00FC",
	"U+021B",
	"U+017D",
	"U+00ED",
	"U+00EE",
	"U+00EA",
	"U+00F0",
	"U+00E7",
	"U+0161",
	"U+00E0",
	"U+00CE",
	"U+0121",
	"U+1E17",
	"U+0127",
	"U+00E4",
	"U+016B",
	"U+00F1",
	"U+0151",
	"U+0126",
	"U+00F9",
	"U+0153",
	"U+03AF",
	"U+03C8",
	"U+010A",
	"U+00BF",
	"U+00FB",
	"U+00EC",
	"U+0160",
	"U+0107",
	"U+0219",
	"U+00C1",
	"U+010D",
	"U+00FE",
	"U+00E3",
	"U+00E2",
	"U+00F2",
	"U+00C7",
	"U+017C",
	"U+00F3",
	"U+00EB",
	"U+00D8",
	"U+00C9",
	"U+00CD",
	"U+00E8",
	"U+1EA3",
];

export const fontFaces: FontFace[] = [
	{
		family: "Fallback Arial Heading",
		fileName: "local(Arial)",
		sizeAdjust: "104%",
		ascentOverride: "94%",
	},
	{
		family: "Fallback Arial Body",
		fileName: "local(Arial)",
		sizeAdjust: "106%",
		ascentOverride: "89%",
	},
	{
		family: "Inter",
		style: "normal",
		weight: 400,
		fileName: fontFilenames["inter-normal.woff2"],
		display: "swap",
	},
	{
		family: "Inter",
		style: "normal",
		weight: 700,
		fileName: fontFilenames["inter-normal.woff2"],
		display: "swap",
	},
	{
		family: "National 2",
		style: "normal",
		weight: 400,
		fileName: fontFilenames["national-2-normal-700.woff2"],
		display: "swap",
	},
	{
		family: "National 2",
		style: "normal",
		weight: 700,
		fileName: fontFilenames["national-2-normal-700.woff2"],
		display: "swap",
	},
	{
		family: "National 2",
		style: "normal",
		weight: 400,
		fileName: fontFilenames["national-2-special-chars-700.woff2"],
		display: "swap",
		unicodeRange,
	},
	{
		family: "National 2",
		style: "normal",
		weight: 700,
		fileName: fontFilenames["national-2-special-chars-700.woff2"],
		display: "swap",
		unicodeRange,
	},
	{
		family: "Inter",
		style: "normal",
		weight: 400,
		fileName: fontFilenames["inter-special-chars.woff2"],
		display: "swap",
		unicodeRange,
	},
	{
		family: "Inter",
		style: "normal",
		weight: 700,
		fileName: fontFilenames["inter-special-chars.woff2"],
		display: "swap",
		unicodeRange,
	},
];
